import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueFormulate from '@braid/vue-formulate'
import './style/formulate.css'
import { ja } from '@braid/vue-formulate-i18n'
import InfiniteLoading from 'vue-infinite-loading';
import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";

Vue.use(InfiniteLoading, { /* options */ });
Vue.config.productionTip = false
Vue.use(VueFormulate, {
    plugins: [ ja ],
    locale: 'ja'
})
// Vue.use(VueAnalytics, {
//   id: 'UA-217913218-1',
//   router
// });
Vue.use(VueGtag, {
  config: { id: "UA-218466642-1" },
  // enabled: false
},router);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
